import { Lang } from "@/types/lang";

export default <Lang>{
  termsHeading1: "Bedingungen und Konditionen",
  termsHeading2: "Gewährleistung und Haftung",
  termsHeading3: "Der Inhalt",
  termsHeading4: "Eingeschränkter Inhalt",
  termsHeading5: "Eingeschränkte Länder",
  termsHeading6: "Zahlungsverfahren",
  termsHeading7: "Erstattungspolitik",
  termsHeading8: "Dienste von Drittanbietern",
  termsHeading9: "Urheberrechte",
  termsHeading10: "Beendigung",

  terms1:
    'Bevor Sie die von AI Factory (das "Unternehmen", "wir", "uns", "die Plattform", die "Dienstleistung(en)" oder "die Website") angebotenen Dienstleistungen nutzen, wird empfohlen, die folgenden Allgemeinen Geschäftsbedingungen ("Vereinbarung") zu lesen. Wenn Sie auf die Website oder die Dienste in irgendeiner Weise zugreifen, bestätigen Sie, dass Sie die Allgemeinen Geschäftsbedingungen dieser Vereinbarung gelesen, verstanden und akzeptiert haben und dass Sie sich mit den Einschränkungen aller anderen Bedingungen einverstanden erklären. Ein Verstoß gegen eine der Bedingungen dieser Vereinbarung führt zum Erlöschen des Rechts, die Plattform oder die Dienste zu nutzen. Wenn Sie mit diesen Bedingungen nicht einverstanden sind, nutzen Sie die Website bitte NICHT.\n' +
    "Aifactorysolution.com ist Eigentum und wird betrieben von Techfox Group LTD.\n" +
    "Techfox Group LTD. wird nach den Gesetzen der Republik Zypern als Gesellschaft mit beschränkter Haftung geführt, mit eingetragenem Sitz in 22A Agias Zonis, Büro 101, 3027 Limassol, Zypern\n" +
    "Registrierungsnummer: HE 436440\n" +
    "Telefon-Nummer: +357 968 78 662\n" +
    "E-Mail: contact{'@'}aifactorysolution.com\n" +
    "Zulässigkeit der Nutzung\n" +
    "Sie sichern uns zu, dass:\n" +
    "- Sie in dem Land, in dem Sie wohnen, volljährig sind;\n" +
    "- Sie in der Lage sind, einen verbindlichen Vertrag abzuschließen und Ihnen die Nutzung der Dienste nicht gesetzlich untersagt ist;\n" +
    "- dass die Informationen, die Sie uns zur Verfügung stellen, korrekt und wahrheitsgemäß sind;\n" +
    "- Sie sind rechtlich befugt, jede Zahlungsmethode, einschließlich Kredit- oder Debitkarten, für die Ausführung einer Bestellung zu verwenden. (Durch die Bereitstellung solcher Informationen erkennen Sie an und ermächtigen uns, die Informationen an Dritte zur Zahlungsabwicklung weiterzugeben, um die Ausführung Ihrer Bestellung zu erleichtern. Bei Verdacht auf Betrug, eine nicht autorisierte oder illegale Transaktion behalten wir uns das Recht vor, Ihre Bestellung jederzeit aus bestimmten Gründen abzulehnen oder zu stornieren, z. B. bei Nichtverfügbarkeit des Dienstes, technischen Fehlern oder falschen Preisen).\n" +
    "In Gerichtsbarkeiten, in denen dies gesetzlich verboten ist, wird diese Vereinbarung ungültig, und das Recht auf Zugang zu den Diensten wird widerrufen.",
  terms2:
    "Die Plattform wird im Hinblick auf die Verfügbarkeit, Fehler und Ungenauigkeiten unserer Dienste ständig aktualisiert. Unsere Plattform kann Dienste enthalten, die mit ungenauen Preisen versehen sind, als veraltet beschrieben werden oder nicht jederzeit verfügbar sind, und es kann zu Verzögerungen bei der Aktualisierung von Informationen über unsere Dienste auf der Plattform und bei der Werbung auf anderen Websites kommen.\n" +
    "Wir sind befugt, Informationen ohne vorherige Ankündigung zu ändern oder zu überarbeiten, einschließlich aller Ungenauigkeiten oder Auslassungen. Unabhängig davon, ob Sie eine Kredit- oder eine Debitkarte verwenden, kann die Zahlung mit der Karte erfolgen, die Ihnen ausgestellt wurde, und wird vom Kartenaussteller validiert. Sollten wir nicht in der Lage sein, die erforderliche Genehmigung zu erhalten, können wir nicht für eine Verzögerung oder Nichtlieferung Ihrer Bestellung verantwortlich gemacht werden.\n" +
    "Sie sind verpflichtet, Ihr Passwort geheim zu halten, wenn Sie es an andere weitergeben. Wenn Sie feststellen, dass Ihr Konto gehackt oder für illegale Zwecke verwendet wurde, müssen Sie uns umgehend darüber informieren.\n" +
    "Es liegt in Ihrer Verantwortung sicherzustellen, dass der Inhalt, der unter Verwendung unserer Dienstleistung(en) erstellt wurde, den gesetzlichen Anforderungen entspricht, einschließlich seiner Korrektheit und Angemessenheit. Das Unternehmen gewährt Ihnen das Recht und die Lizenz, die über unsere Plattform erstellten Inhalte zu nutzen, zu ändern, auszustellen, zu kopieren (in ihrer Gesamtheit), zu vervielfältigen und zu verbreiten. Das Unternehmen gewährt Ihnen die vollständigen Rechte an allen von Ihnen erstellten Inhalten, was bedeutet, dass Sie für den Schutz und die Wahrung dieser Rechte verantwortlich sind.\n" +
    "Die Nutzung des Dienstes erfolgt auf Ihr eigenes Risiko. Sie erkennen an, dass der Dienst Inhalte enthalten kann, die als beleidigend, unanständig, unrichtig oder anstößig angesehen werden können, und Sie erklären sich damit einverstanden, dass keine Haftung für Fehler oder Schäden besteht, die sich aus Ihrer Nutzung unserer Plattform ergeben. Wenn Sie sich bei der Nutzung der Dienste auf betrügerisches, missbräuchliches oder anderweitig illegales Verhalten einlassen, kann Ihr Konto gekündigt werden. Es ist Ihnen untersagt, Nachrichten oder Aufforderungen zu veröffentlichen oder zu übermitteln, die darauf abzielen, Passwörter, Konten oder private Informationen von anderen Nutzern der Plattform zu erhalten. Es ist ausdrücklich untersagt, die Dienste zu dem Zweck zu nutzen, die Sicherheit eines Computernetzwerks zu verletzen, Passwörter oder Sicherheitsverschlüsselungscodes zu knacken oder illegales Material (einschließlich potenziell schädlicher oder illegaler Inhalte) zu übertragen oder zu speichern sowie an illegalen Aktivitäten teilzunehmen.\n" +
    "Der Quellcode der Plattform wird nicht dekompiliert, zurückentwickelt oder auf andere Weise beschafft. Das Unternehmen führt regelmäßige Backups der Inhalte durch, die jedoch keinen 100%igen Schutz vor Beschädigung oder Verlust der Daten garantieren. Das Unternehmen wird technische Unterstützung leisten und sich bemühen, alle auftretenden Probleme zu lösen, unabhängig davon, ob sie bekannt sind oder entdeckt werden, die die Backups der Inhalte betreffen. Das Unternehmen ist nicht verantwortlich für die Integrität der Inhalte oder dafür, dass diese nicht erfolgreich in einen brauchbaren Zustand zurückversetzt werden können.",
  terms3:
    "Unser Service ermöglicht es Ihnen, Audio-, Text- und Bildinhalte zu erstellen. Sie sind für die Inhalte verantwortlich, die Sie mit unseren Diensten erstellen, einschließlich ihrer Rechtmäßigkeit und Angemessenheit.\n" +
    "Durch die Erstellung von Inhalten auf der Plattform gewähren Sie uns das Recht und die Lizenz, diese Inhalte auf und über den Dienst zu verwenden, zu ändern, öffentlich aufzuführen, öffentlich darzustellen, zu reproduzieren und zu verbreiten. Sie behalten alle Rechte an den von Ihnen erstellten, veröffentlichten oder im Rahmen des Dienstes angezeigten Inhalten, und Sie sind für den Schutz dieser Rechte verantwortlich.\n" +
    "Mit der Nutzung unserer Dienste erklären Sie sich damit einverstanden, dass das Unternehmen nicht garantieren kann, dass die erstellten Inhalte zu 100 % korrekt, von bester Qualität oder vollständig wahrheitsgetreu sind, und dass daher keine Rückerstattungen oder Rückfragen bezüglich der endgültigen Qualität der Inhalte möglich sind.",
  terms4:
    "Sie sind sich bewusst und erklären sich damit einverstanden, dass Sie die volle Verantwortung für den Inhalt sowie für alle mit Ihrem Konto verbundenen Aktivitäten tragen, unabhängig davon, ob Sie oder ein anderer Nutzer diese mit Zugang zu Ihrem Konto durchführen.\n" +
    "Es ist Ihnen untersagt, Inhalte zu erstellen oder zu übermitteln, die illegal, beleidigend, schändlich, abstoßend, bedrohlich, verleumderisch, unanständig, ungesetzlich oder anderweitig anstößig sind. Die folgende Liste von Inhalten kann als eingeschränkt angesehen werden, ist aber nicht beschränkt auf:\n" +
    "- Beteiligung an einer ungesetzlichen Aktivität oder Ermutigung zu einem solchen illegalen Verhalten;\n" +
    "- Die Nutzung der Plattform zur Erstellung von Materialien, die diffamierend, diskriminierend und beleidigend sind, einschließlich jeglicher Erwähnung oder Bemerkung in Bezug auf Religion, Ethnie, sexuelle Orientierung/Geschlechtsidentität, nationale/ethnische Herkunft oder andere Gruppen;\n" +
    "- Nutzung des Dienstes zur Förderung verbotener Aktivitäten, wie z. B. des Konsums illegaler Substanzen/Drogen und Dienstleistungen für Erwachsene;\n" +
    "- Generierung von Spam, maschinell generierten oder zufällig zugewiesenen Aufgaben, unerlaubter oder unaufgeforderter Werbung und Kettenbriefen;\n" +
    "- Jede andere Form von ungesicherter Aufforderung oder jede Art von Lotterie oder Glücksspiel;\n" +
    "- Nutzung des Dienstes zur Erstellung oder Entwicklung von Viren, Malware, trojanischen Pferden oder ähnlichem Material, das dazu bestimmt ist, den Betrieb von Software zu stören, zu beschädigen oder zu beeinträchtigen;\n" +
    "- Verletzung der Rechte am geistigen Eigentum einer anderen Partei, einschließlich, aber nicht beschränkt auf: Patente, Marken oder Geschäftsgeheimnisse sowie Urheberrechte und Veröffentlichungsrechte;\n" +
    "- Nachahmung von Personen, einschließlich Mitarbeitern und Vertretern des Unternehmens;\n" +
    "- Gefährdung der Privatsphäre anderer.\n" +
    "Sofern nicht anders vereinbart, ist das Unternehmen befugt, sexuell eindeutige Inhalte abzulehnen oder zu löschen und darüber zu entscheiden, ob sie für die Nutzung oder die Einhaltung dieser Bedingungen geeignet sind. Dem Unternehmen steht es frei, die Art und Weise, wie der Inhalt präsentiert wird, zu modifizieren und Änderungen vorzunehmen. Das Unternehmen ist befugt, den Dienst einzuschränken oder zu sperren, wenn Sie ihn zur Erstellung von Inhalten nutzen, die als anstößig angesehen werden. Das Unternehmen ist nicht verantwortlich für die Inhalte, die von Nutzern und/oder Dritten auf anderen Plattformen veröffentlicht werden.",
  terms5:
    "Um die Dienste nutzen zu können, muss ein Benutzer seine E-Mail-Adresse bestätigen. Die Nutzung der Dienste ist jedoch für Einwohner der folgenden Länder eingeschränkt: Afghanistan, Albanien, Barbados, Burkina Faso, Kambodscha, Kaimaninseln, Demokratische Republik Kongo, Gibraltar, Haiti, Jamaika, Jordanien, Mali, Marokko, Mosambik , Nicaragua, Pakistan, Panama, Philippinen, Russland, Senegal, Südsudan, Syrien, Tansania, Trinidad und Tobago, Türkei, Uganda, Vereinigte Arabische Emirate, Vanuatu, Jemen, Simbabwe Nordkorea (Demokratische Volksrepublik Korea), Iran, Myanmar .\n",
  terms6:
    'Um auf die Dienste zugreifen zu können, müssen Sie über einen ausreichenden Betrag des plattforminternen Guthabens - "Neuronen" - in Ihrem Wallet-Konto verfügen. "Neuronen" bieten den Nutzern einen flexiblen Zugang zu den auf der Plattform verfügbaren Diensten. Sie können Ihr Guthaben im entsprechenden Bereich der Website überprüfen und aufladen. Neuronen haben außerhalb der Plattform keinen Wert, können nur auf der Plattform verwendet werden und können nicht auf eine andere Plattform oder an eine andere Person übertragen werden. 10 Neuronen entsprechen 1 Euro.\n' +
    "Transaktionen werden innerhalb von 72 Stunden nach der Zahlung bearbeitet.\n" +
    'Nachdem die Zahlung abgeschlossen ist, kann es bis zu 24 Stunden dauern, bis Ihr Guthaben aktualisiert wird, obwohl dieser Vorgang normalerweise sofort erfolgt. Wir sind berechtigt, einen dritten Zahlungsdienstleister (der "Zahlungsabwickler") für Ihre Einzahlungen einzusetzen. Der jeweilige Zahlungsabwickler ist in erster Linie dafür verantwortlich, die Zahlung Ihrer Transaktionen zu ermöglichen. Damit Ihre Transaktion bearbeitet werden kann, benötigt der Zahlungsabwickler Ihre persönlichen Daten und Ihre Zahlungsdaten, wie z. B. Debit-/Kreditkartendaten, Adresse und andere Informationen. Diese Informationen werden nur zur Abwicklung der Transaktionen verwendet und nicht an Dritte weitergegeben, mit Ausnahme der Finanz- und Regulierungsbehörden, die an der Abwicklung oder den Compliance-Verfahren beteiligt sind. Mehr über die Verwendung Ihrer persönlichen Daten erfahren Sie in unserer Datenschutzrichtlinie.\n' +
    "Wir behalten uns das Recht vor, alle von Ihnen bei der Nutzung des Dienstes getätigten Transaktionen zu überprüfen und zu genehmigen, um sicherzustellen, dass sie mit diesen Allgemeinen Geschäftsbedingungen und den der Plattform von Drittanbietern auferlegten Bedingungen übereinstimmen. Sie erkennen ausdrücklich an und erklären sich damit einverstanden, dass solche Überprüfungen von Ihnen verlangen können, dass Sie uns zusätzliche persönliche Informationen zur Verfügung stellen, um Ihre Identität zu überprüfen und zu bestätigen und um Überprüfungen durchzuführen, die darauf abzielen, Betrug und Missbrauch des Dienstes zu verhindern, in Übereinstimmung mit unserer Datenschutzrichtlinie und allen geltenden Gesetzen und Vorschriften.\n" +
    "Indem Sie mit einer Transaktion fortfahren, bestätigen Sie, dass alle Bankkarten und anderen Zahlungskonten, die Sie für Transaktionen verwenden, rechtmäßig Ihnen gehören.",
  terms7:
    "Wir garantieren keine Rückerstattungen, wenn der bezahlte Dienst bereits erbracht wurde. Sie haben keinen Anspruch auf Rückerstattung von bereits gezahlten Gebühren oder Transaktionsprovisionen.\n" +
    "Rückerstattungen sind nur für Transaktionen möglich, die für den Kauf von Neuronen durchgeführt wurden, und nur im Falle von technischen Fehlern mit dem auf der Website bereitgestellten Dienst und/oder anderen Problemen, die sich aus unseren eigenen Problemen ergeben und die dazu geführt haben, dass Sie die von Ihnen bezahlten Dienste nicht in Anspruch nehmen konnten.\n" +
    '"Technische Probleme" werden definiert als erhebliche Leistungsprobleme, Systemabstürze oder die Unfähigkeit, auf wesentliche Funktionen unserer Plattform zuzugreifen, aufgrund von Fehlern, die auf unsere Systeme zurückzuführen sind. Bei Verdacht auf Betrug oder Missbrauch unserer Dienste werden keine Rückerstattungen gewährt. Das Unternehmen behält sich das Recht vor, jegliche Ansprüche gründlich zu untersuchen und gegebenenfalls geeignete rechtliche Schritte einzuleiten, um unsere Interessen und die unserer rechtmäßigen Nutzer zu schützen.\n' +
    "Rückerstattungsanträge müssen spätestens 24 Stunden nach dem Transaktionsdatum über contact{'@'}aifactorysolution.com eingereicht werden. Erstattungsanträge werden innerhalb von 14 Werktagen ab dem Datum der Einreichung bearbeitet.\n" +
    "Die Nutzer müssen detaillierte Beschreibungen, Screenshots, Fehlermeldungen oder andere relevante Unterlagen zur Verfügung stellen, die ihre Behauptungen über die aufgetretenen technischen Probleme untermauern. Anträge können abgelehnt werden, wenn sie die genannten Kriterien nicht erfüllen, einschließlich unzureichender Beweise für technische Probleme oder Fälle von Betrug oder Missbrauch.\n" +
    "Rückerstattungen werden nicht gewährt, wenn der Nutzer ohne triftige technische Gründe mit dem Produkt, der Dienstleistung oder der Qualität der erstellten Inhalte unzufrieden ist.",
  terms8:
    "Links auf unserer Plattform können zu Websites oder Diensten von Dritten führen, die nicht dem Unternehmen gehören. Websites oder Dienste Dritter können mit dem Unternehmen verlinkt sein, aber das Unternehmen ist nicht für deren Inhalte, Datenschutzrichtlinien oder Praktiken verantwortlich. Sie erkennen an und erklären sich damit einverstanden, dass das Unternehmen nicht für Schäden oder Verluste haftbar gemacht werden kann, die durch oder in Verbindung mit der Nutzung von oder dem Vertrauen auf solche Inhalte, Waren oder Dienstleistungen entstehen, die über Websites oder andere Dienste angeboten werden. Wir empfehlen Ihnen dringend, die Nutzungsbedingungen sowie die Datenschutzrichtlinien von Websites oder Diensten Dritter, auf die Sie zugreifen, zu lesen.",
  terms9:
    "Der Grundsatz des Urheberrechts verlangt von uns, die geistigen Eigentumsrechte anderer anzuerkennen und zu respektieren. Es gehört zu unseren Grundsätzen, jeder Behauptung nachzugehen, dass die im Dienst veröffentlichten Inhalte die geistigen Eigentumsrechte oder das Urheberrecht anderer verletzen. Falls Sie der Meinung sind, dass der Dienst eine Urheberrechtsverletzung begünstigt, und Sie entweder der Inhaber des Urheberrechts sind oder in dessen Namen bevollmächtigt wurden, senden Sie uns bitte eine E-Mail an contact{'@'}aifactorysolution.com, um Ihre Mitteilung einzureichen. Bitte fügen Sie Ihrer Meldung eine ausführliche Erläuterung des vermeintlichen Verstoßes bei. Das Eingeständnis, dass Inhalte Ihr Urheberrecht verletzen, kann dazu führen, dass Sie für Schäden, einschließlich Kosten und Anwaltsgebühren, zur Verantwortung gezogen werden.",
  terms10:
    "Wir sind berechtigt, Ihr Konto ohne vorherige Ankündigung oder Haftung zu sperren oder zu kündigen, auch wenn Sie nicht gegen die Bedingungen verstoßen. Dies gilt ungeachtet des Grundes. Ihre Nutzung des Dienstes wird mit der Löschung Ihres Kontos beendet. Wenn Sie Ihr Konto von der Plattform entfernen möchten, können Sie die Nutzung des Dienstes einfach einstellen. Diese Vereinbarung bleibt in vollem Umfang in Kraft, solange Sie die Dienste nutzen. Das Unternehmen ist jederzeit berechtigt, Ihren Zugang zu den Diensten zu beenden oder auszusetzen. Im Falle eines Verstoßes gegen eine der Bedingungen dieser Vereinbarung kann das Unternehmen alle Dienste und den Zugang zur Website ohne jegliche Haftung oder Warnung sofort aussetzen oder beenden.\n" +
    "Alle Bestimmungen dieser Vereinbarung, die nach der Beendigung in Kraft bleiben, sind wesentlich, einschließlich der Eigentumsbestimmungen und Haftungsausschlüsse. Diese Einschränkungen gelten auch für Haftungsbeschränkungen. Das Unternehmen, seine Muttergesellschaften, Tochtergesellschaften, verbundenen Unternehmen und Mitarbeiter sind in vollem Umfang verpflichtet, sich gegen jegliche Ansprüche, Haftungen, Vergleiche/Kosten, einschließlich Anwaltsgebühren oder Schäden zu verteidigen, die sich aus Ihrer Nutzung der Dienste, der Verletzung dieser Vereinbarung oder der Verletzung Ihres geistigen Eigentums oder anderer Rechte durch Sie oder eine andere Partei ergeben.\n" +
    "Sollte eine Bestimmung dieser Bedingungen als nicht durchsetzbar oder ungültig erachtet werden, werden die anderen Bestimmungen so geändert und ausgelegt, dass der beabsichtigte Zweck gemäß dem anwendbaren Recht erreicht wird, ohne dass diese ersetzt werden. Sofern nicht anders vereinbart, beeinträchtigt die Nichtanwendung eines Rechts oder einer Verpflichtung gemäß diesen Bedingungen nicht die Fähigkeit einer der Parteien, die Einhaltung dieser Vereinbarung durchzusetzen. Die ursprüngliche englische Version der Bedingungen ist in allen Streitfällen das maßgebliche Recht.\n" +
    "Diese Bedingungen können sich ändern und können von uns nach unserem alleinigen Ermessen geändert oder ersetzt werden. Sie erkennen die überarbeiteten Bedingungen an und erklären sich damit einverstanden, an diese gebunden zu sein, wenn Sie nach Inkrafttreten dieser Änderungen weiterhin auf unseren Service zugreifen oder ihn nutzen. Wenn Sie mit den neuen Bedingungen nicht einverstanden sind, stellen Sie bitte die Nutzung der Website und des Service ein.\n" +
    "Vielen Dank, dass Sie sich für AI Factory entschieden haben! Sollten Sie Fragen zu diesen Geschäftsbedingungen haben, wenden Sie sich bitte an contact{'@'}aifactorysolution.com.",
  updated: "Zuletzt aktualisiert am 01 Aug 2025",
};
