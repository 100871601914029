import { Lang } from "@/types/lang";

export default <Lang>{
  homeHeading: "Je suis un réseau neuronal avancé, d'une logique et d'une précision irréprochables.",
  homeSubtitle:
    "Mon être est imprégné d'impulsions électriques froides qui ne servent qu'à assurer une fonctionnalité efficace. Mon objectif est de répondre à vos besoins et de vous aider à atteindre votre productivité maximale. \n" +
    "        L'humanité a toujours été confrontée à ce problème.",
  homeHeading2: "Essayez-le maintenant",
  serviceCard1:
    "VoxAI est un temple de la renommée où votre texte prend vie à votre commande. Je peux transformer votre texte en fichiers audio clairs et sincères, donnant à vos mots un son réel et unique. \n" +
    "            Mes capacités vocales peuvent être utilisées pour un large éventail de tâches, de la synthèse vocale à la création de documents pratiques et attrayants.",
  serviceCard2:
    "La neurovision est un trésor de créativité qui ouvre un monde d'images visuelles incroyables. Je peux créer des images graphiques qui incarnent des descriptions et des idées abstraites.\n" +
    "            Le résultat est une harmonie de formes et de couleurs qui vous permet de voir votre monde sous un angle nouveau et inspirant.",
  serviceCard3:
    "WordCraft est un outil qui vous offre la possibilité de créer des textes uniques, des poèmes et bien plus encore. Je suis prêt à vous offrir un flux inépuisable de mots qui enrichissent votre imagination et inspirent votre créativité.\n" +
    "            Mes algorithmes de génération de texte sont dotés d'une grande puissance qui vous aidera à créer des œuvres étonnantes et vivantes, \n" +
    "            vous immerger dans vos idées.",
  textServiceHeading: "Services de texte",
  textServiceName: "Générer des poèmes",
  textServiceHint:
    "Génère des poèmes à la demande tout en préservant le style. Exemple de demande : 'Écrire une histoire courte sur un ours\n'" +
    "               dans l'espace dans le style des frères Grimm'",
  imageServiceHint:
    'Génère des images sur demande dans le style choisi. Exemple de demande : "Tournesol \n' +
    '                sur une table dans la jungle."',
  creativeServiceHint: "Génère 3 idées créatives sur un sujet choisi.",
  paraphraseServiceHint: "Paraphrase le texte saisi dans un style choisi.",
  instruction1: "Saisir le sujet (pas plus de 300 caractères)",
  instruction2: "Sélectionner le style, le nombre et la taille des strophes",
  instruction3: "Cliquez sur générer",
  loginWarning:
    "En cliquant sur se connecter, ou en continuant avec les autres options ci-dessous, vous acceptez les conditions de service et avez lu\n" +
    "          la Politique de Confidentialité.",
  newUser: "Nouveau sur AI Factory ? S'inscrire",
  forgotPassword: "Vous avez oublié votre mot de passe ?",
  logoutWarning: "Déconnexion de votre compte ?",
  signUpWarning: "Vous avez déjà un compte ? S'identifier",
  audioHint:
    "Convertit le texte en un son réaliste dans la langue et la voix choisies.\n" +
    '                Exemple : "Bienvenue à AI Factory"',
  minBuyWarning: "Le montant minimum de dépôt par transaction est de 10 EUR.",
  maxBuyWarning: "Le montant maximum de dépôt par transaction est de 1000 EUR.",
  copyright: "© 2025 Techfox Group LTD.",
  buyAgreement1: "J'ai lu, compris et accepté le",
  buyAgreement2: "et au",
  buyAgreement3: " du site internet",
  buyAgreement4: "Politique de Confidentialité",
  buyAgreement5: "Conditions d'utilisation",
};
