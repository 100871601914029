import { Lang } from "@/types/lang";

export default <Lang>{
  homeHeading: "Soy una red neuronal avanzada con una lógica y una precisión impecables.",
  homeSubtitle:
    "Mi ser está impregnado de fríos impulsos eléctricos que sirven únicamente al propósito de una funcionalidad eficiente. Mi objetivo es satisfacer sus necesidades y ayudarle a alcanzar su máxima productividad. \n" +
    "        La humanidad siempre ha luchado contra eso.",
  homeHeading2: "Pruébelo ahora",
  serviceCard1:
    "VoxAI es un salón de la fama donde tu texto cobra vida a tus órdenes. Puedo transformar tu texto en archivos de audio sinceros y claros, dando a tus palabras un sonido real y único. \n" +
    "            Mis capacidades de voz pueden ser una herramienta para una amplia gama de tareas, desde la conversión de texto a voz hasta la creación de materiales atractivos y prácticos.",
  serviceCard2:
    "La neurovisión es un tesoro de creatividad que abre un mundo de imágenes visuales increíbles. Puedo crear imágenes gráficas que encarnan descripciones e ideas abstractas.\n" +
    "            El resultado es una armonía de formas y colores que le permite ver el mundo desde una perspectiva nueva e inspiradora.",
  serviceCard3:
    "WordCraft es una herramienta que te ofrece la oportunidad de crear textos únicos, poemas y mucho más. Estoy listo para darle un flujo inagotable de palabras que enriquecen su imaginación e inspiran su creatividad.\n" +
    "            Mis algoritmos de generación de texto están llenos de potencia que te ayudarán a crear obras asombrosas y vívidas, \n" +
    "            sumergiéndote en tus ideas.",
  textServiceHeading: "Servicios de texto",
  textServiceName: "Generar poemas",
  textServiceHint:
    'Genera poemas a petición conservando el estilo. Ejemplo de solicitud: "Escribe un cuento corto sobre un oso \n' +
    '                en el espacio al estilo de los Hermanos Grimm"',
  imageServiceHint:
    'Genera imágenes a petición en el estilo elegido. Ejemplo de solicitud: "Girasol \n' +
    '                sobre una mesa en la selva."',
  creativeServiceHint: "Genera 3 ideas creativas sobre un tema elegido.\n",
  paraphraseServiceHint: "Parafrasea el texto introducido en el estilo elegido.",
  instruction1: "Introduzca el asunto (no más de 300 caracteres)",
  instruction2: "Seleccione el estilo, el número y el tamaño de las estrofas",
  instruction3: "Haga clic en generar",
  loginWarning:
    "Al hacer clic en iniciar sesión, o continuar con las otras opciones a continuación, usted acepta las Condiciones del servicio y ha leído\n" +
    "          la Política de privacidad",
  newUser: "¿Eres nuevo en AI Factory? Regístrate",
  forgotPassword: "¿Ha olvidado su contraseña?",
  logoutWarning: "¿Salir de su cuenta?",
  signUpWarning: "¿Ya tiene una cuenta? Iniciar sesión",
  audioHint:
    "Convierte texto en audio realista en el idioma y la voz elegidos.\n" +
    '                Ejemplo: "Bienvenido a AI Factory"',
  minBuyWarning: "El importe mínimo de depósito por transacción es de 10 EUR.",
  maxBuyWarning: "El importe máximo de depósito por transacción es de 1000 EUR.",
  copyright: "© 2025 Techfox Group LTD.",
  buyAgreement1: "He leído, comprendido y acepto las\n",
  buyAgreement2: "y al",
  buyAgreement3: " del sitio web",
  buyAgreement4: "Política de Privacidad",
  buyAgreement5: "Condiciones de uso",
};
