import { Lang } from "@/types/lang";
export default <Lang>{
  termsHeading1: "Voorwaarden en bepalingen",
  termsHeading2: "Garantie en Aansprakelijkheid",
  termsHeading3: "De inhoud",
  termsHeading4: "Beperkte inhoud",
  termsHeading5: "Beperkte landen",
  termsHeading6: "Betalingsprocedure",
  termsHeading7: "Restitutiebeleid",
  termsHeading8: "Diensten van derden",
  termsHeading9: "Auteursrechten",
  termsHeading10: "Beëindiging",

  terms1:
    "Voordat u de diensten van AI Factory (het 'Bedrijf', 'Wij', 'Ons', 'het Platform', de 'Dienst(en)' of 'de Website') gebruikt, wordt u aangeraden de volgende Algemene voorwaarden ('Overeenkomst') te lezen. Als u de website of diensten op enigerlei wijze benadert, erkent u dat u de Algemene Voorwaarden van deze Overeenkomst hebt gelezen, begrepen en geaccepteerd, en dat u ermee instemt gebonden te zijn aan de beperkingen van alle andere voorwaarden. Schending van een van de Voorwaarden van deze overeenkomst resulteert in de beëindiging van elk recht om het Platform of de Diensten te gebruiken. Als je niet akkoord gaat met deze Voorwaarden, maak dan GEEN gebruik van de Website.\n" +
    "Aifactorysolution.com is eigendom van en wordt geëxploiteerd door Techfox Group LTD.\n" +
    "Techfox Group LTD. Is een besloten vennootschap naar Cypriotisch recht met statutaire zetel op 22A Agias Zonis, kantoor 101, 3027 Limassol, Cyprus.\n" +
    "Registratienummer: HE 436440\n" +
    "Telefoonnummer: +357 968 78 662\n" +
    "E-mail: contact{'@'}aifactorysolution.com\n" +
    "Toelaatbaarheid van gebruik\n" +
    "U garandeert ons dat:\n" +
    "- U meerderjarig bent in het land waar u woont;\n" +
    "- U in staat bent om een bindend contract aan te gaan en dat het u wettelijk niet verboden is om de Diensten te gebruiken of er toegang toe te hebben;\n" +
    "- Dat de informatie die u aan ons verstrekt accuraat en waarheidsgetrouw is;\n" +
    "- U wettelijk bevoegd bent om een betalingsmethode te gebruiken, inclusief credit- of debitcard voor het uitvoeren van een bestelling (Door het verstrekken van dergelijke informatie, erkent u en machtigt u ons om de informatie te delen met betalingsverwerkende derden om de voltooiing van uw bestelling te vergemakkelijken. Als Wij fraude, een ongeautoriseerde of illegale transactie vermoeden, behouden Wij ons het recht voor om uw bestelling op elk gewenst moment te weigeren of te annuleren om bepaalde redenen, waaronder het niet beschikbaar zijn van de service, technische fouten of onjuiste prijzen).\n" +
    "In rechtsgebieden waar dit wettelijk verboden is, wordt deze Overeenkomst ongeldig en wordt het recht op toegang tot de Services ingetrokken.",
  terms2:
    "Het Platform wordt voortdurend bijgewerkt met betrekking tot de beschikbaarheid, fouten en onnauwkeurigheden van Onze Diensten. Ons Platform kan Diensten hebben die onjuist geprijsd zijn, beschreven zijn als oudbakken of niet altijd beschikbaar zijn, en We kunnen vertragingen ervaren in het updaten van informatie over onze Diensten op het Platform en in advertenties op andere websites.\n" +
    "Wij hebben de bevoegdheid om informatie te wijzigen of te herzien zonder voorafgaande kennisgeving, inclusief eventuele onnauwkeurigheden of weglatingen. Of je nu een creditcard of bankpas gebruikt, de betaling kan worden gedaan met de kaart die aan jou is uitgegeven en de kaartuitgever zal deze valideren. In het geval dat wij niet in staat zijn om de benodigde autorisatie te verkrijgen, kunnen wij niet aansprakelijk worden gesteld voor enige vertraging of niet-levering van uw bestelling.\n" +
    "U bent verplicht tot geheimhouding wanneer u uw wachtwoord met anderen deelt. Wanneer U ontdekt dat Uw account is gehackt of voor illegale doeleinden wordt gebruikt, dient U ons hiervan onmiddellijk op de hoogte te stellen.\n" +
    "Het is uw verantwoordelijkheid om ervoor te zorgen dat de Content die wordt gecreëerd met behulp van Onze Service(s) voldoet aan de wettelijke vereisten, met inbegrip van de juistheid en geschiktheid ervan. De Onderneming verleent u het recht en de licentie om Content die is gemaakt via ons platform te gebruiken, te wijzigen, tentoon te stellen, te kopiëren (in zijn geheel), te reproduceren en te distribueren. Het Bedrijf verleent u volledige rechten op alle door u gegenereerde Content, wat betekent dat u verantwoordelijk bent voor het beschermen en behouden van deze rechten.\n" +
    "U gebruikt de Service op eigen risico. U erkent dat de Service inhoud kan bevatten die als beledigend, onfatsoenlijk, onjuist of aanstootgevend kan worden beschouwd, en u gaat ermee akkoord dat er geen aansprakelijkheid bestaat voor eventuele fouten of schade als gevolg van uw gebruik van ons Platform. Als u zich inlaat met frauduleus, beledigend of anderszins illegaal gedrag tijdens het gebruik van de Diensten dan kan uw account worden beëindigd. Het is verboden om berichten of verzoeken te plaatsen of te verzenden die bedoeld zijn om wachtwoorden, accounts of privé-informatie van andere gebruikers van het Platform te verkrijgen. Het is uitdrukkelijk verboden om de Diensten te gebruiken met als doel inbreuk te maken op de veiligheid van een computernetwerk, het kraken van wachtwoorden of encryptiecodes, of het overdragen of opslaan van illegaal materiaal (met inbegrip van potentieel schadelijke of illegale inhoud), evenals het ontplooien van illegale activiteiten.\n" +
    "De broncode van het Platform zal niet worden gedecompileerd, reverse-engineered of op een andere manier worden verkregen. De Onderneming maakt regelmatig back-ups van de inhoud, maar deze garanderen geen 100% bescherming tegen corruptie of verlies van gegevens. Het Bedrijf zal technische ondersteuning bieden en zich inspannen om eventuele problemen op te lossen, of deze nu bekend zijn of ontdekt worden, die van invloed zijn op de back-ups van de Content. Het Bedrijf is niet verantwoordelijk voor de integriteit van de Content of het niet succesvol herstellen ervan naar een bruikbare staat.",
  terms3:
    "Met onze Service kunt u Audio-, Tekst- en Afbeeldingscontent genereren. U bent verantwoordelijk voor de Content die u genereert met behulp van onze Diensten, met inbegrip van de wettigheid en geschiktheid ervan.\n" +
    "Door Content te genereren op het Platform, verleent u ons het recht en de licentie om dergelijke Content te gebruiken, te wijzigen, publiekelijk uit te voeren, publiekelijk te tonen, te reproduceren en te distribueren op en via de Service. U behoudt al uw rechten op alle Inhoud die u creëert, plaatst of weergeeft op of via de Dienst en u bent verantwoordelijk voor het beschermen van deze rechten.\n" +
    "Het gebruik van onze Diensten houdt in dat u begrijpt dat het Bedrijf niet kan garanderen dat de gegenereerde inhoud 100% correct, van de beste kwaliteit of volledig waarheidsgetrouw is en dat er daarom geen terugbetalingen of vragen over de uiteindelijke kwaliteit van de inhoud kunnen worden gesteld.",
  terms4:
    "U bent zich ervan bewust en gaat ermee akkoord dat u de volledige verantwoordelijkheid draagt voor de Inhoud en voor alle activiteiten die aan uw account zijn gekoppeld, ongeacht of u of een andere gebruiker deze uitvoert met toegang tot uw account.\n" +
    "Het is verboden om Inhoud te maken of te verzenden die illegaal, aanstootgevend, schandelijk, weerzinwekkend, bedreigend, lasterlijk, onfatsoenlijk, onwettig of anderszins aanstootgevend is. De volgende lijst met Inhoud kan als beperkt worden beschouwd, maar is niet beperkt tot:\n" +
    "- Het aangaan van een onwettige activiteit of het aanmoedigen van dergelijk onwettig gedrag;\n" +
    "- Het Platform gebruiken om materiaal te maken dat lasterlijk, discriminerend en beledigend is, inclusief vermeldingen of opmerkingen met betrekking tot religie, ras, seksuele geaardheid/genderidentiteit, nationale/etnische afkomst of andere groepen;\n" +
    "- De Service gebruiken om verboden activiteiten te promoten, zoals het gebruik van illegale stoffen/drugs en diensten voor volwassenen;\n" +
    "- Het genereren van spam, door machines gegenereerde of willekeurig toegewezen taken, ongeoorloofde of ongevraagde reclame, kettingbrieven;\n" +
    "- Elke andere vorm van onbeveiligde verzoeken, of elke vorm van loterij of gokken;\n" +
    "- Het gebruiken van de Service voor het creëren of ontwikkelen van virussen, malwares, trojan horses of soortgelijk materiaal dat is ontworpen om verstoring, schade of interferentie met de werking van software te veroorzaken;\n" +
    "- Inbreuk maken op de intellectuele eigendomsrechten van enige partij, met inbegrip van maar niet beperkt tot: octrooien, handelsmerken of handelsgeheimen, evenals auteursrechten en publiciteitsrechten;\n" +
    "- Zich voordoen als personen, inclusief werknemers en vertegenwoordigers van het Bedrijf;\n" +
    "- De privacy van anderen in gevaar brengen.\n" +
    "Tenzij anders overeengekomen, heeft het bedrijf de bevoegdheid om seksueel expliciete inhoud te weigeren of te verwijderen en te beslissen of deze geschikt is voor gebruik of voldoet aan deze voorwaarden. Het staat het Bedrijf vrij om de manier waarop de Inhoud wordt gepresenteerd aan te passen en wijzigingen aan te brengen. Het Bedrijf heeft de bevoegdheid om de Service te beperken of in te trekken als u deze gebruikt om Inhoud te maken die als aanstootgevend wordt beschouwd. Het Bedrijf is niet verantwoordelijk voor de inhoud die door gebruikers en/of derden op andere platforms wordt geplaatst.",
  terms5:
    "Om de Diensten te kunnen gebruiken, moet een gebruiker zijn e-mailadres bevestigen. Het gebruik van de Diensten is echter beperkt voor inwoners van de volgende landen: Afghanistan, Albanië, Barbados, Burkina Faso, Cambodja, Kaaimaneilanden, Democratische Republiek Congo, Gibraltar, Haïti, Jamaica, Jordanië, Mali, Marokko, Mozambique , Nicaragua, Pakistan, Panama, Filippijnen, Rusland, Senegal, Zuid-Soedan, Syrië, Tanzania, Trinidad en Tobago, Turkije, Oeganda, Verenigde Arabische Emiraten, Vanuatu, Jemen, Zimbabwe Noord-Korea (Democratische Volksrepubliek Korea), Iran, Myanmar .",
  terms6:
    'Om toegang te krijgen tot de Diensten, moet u een voldoende bedrag van het interne saldo van het Platform - "Neurons" - in uw account Portemonnee hebben. "Neurons" bieden gebruikers flexibele toegang tot de diensten die beschikbaar zijn op het platform. U kunt uw saldo controleren en opwaarderen in de overeenstemmende sectie van de Website. Neurons hebben geen waarde buiten het Platform, kunnen alleen worden gebruikt op het Platform en kunnen niet worden overgedragen naar een ander platform of naar een andere persoon. 10 Neurons staan gelijk aan 1 Euro.\n' +
    "Transacties worden binnen 72 uur na betaling verwerkt.\n" +
    'Nadat de betaling is voltooid, kan het tot 24 uur duren voordat je saldo is bijgewerkt, maar meestal is dit proces direct. We hebben het recht om een derde partij payment service processor (de "Payment Processor") te gebruiken voor uw stortingen. De respectieve betalingsverwerker is primair verantwoordelijk voor het faciliteren van de betaling van uw transacties. Om uw transactie te kunnen verwerken, heeft de Betalingsverwerker uw persoonlijke gegevens en uw betalingsgegevens nodig, zoals debet-/creditcardgegevens, adres en andere informatie. Deze informatie wordt alleen gebruikt om de transacties te verwerken en wordt niet doorgegeven aan derden, met als enige uitzondering de financiële en regelgevende instellingen die betrokken zijn bij de verwerkings- of nalevingsprocedures. U kunt meer te weten komen over het gebruik van uw persoonlijke gegevens in Ons Privacybeleid.\n' +
    "Wij behouden ons het recht voor om alle transacties die u maakt bij het gebruik van de Dienst te controleren en goed te keuren om er zeker van te zijn dat ze voldoen aan deze Algemene Voorwaarden en de voorwaarden die door externe serviceproviders aan het Platform worden gesteld. U erkent uitdrukkelijk en gaat ermee akkoord dat dergelijke verificaties van u kunnen vereisen dat u ons aanvullende persoonlijke informatie verstrekt om uw identiteit te verifiëren en te bevestigen en om verificaties uit te voeren gericht op het ontmoedigen van fraude en misbruik van de Dienst, in overeenstemming met Ons Privacybeleid en alle toepasselijke wet- en regelgeving.\n" +
    "Door verder te gaan met een transactie, verklaart u dat alle bankkaarten en andere betaalrekeningen die u gebruikt voor transacties wettelijk de uwe zijn.",
  terms7:
    "We garanderen geen enkele vorm van restitutie als de betaalde dienst is geleverd. Je hebt geen recht op restitutie van kosten of transactieprovisies die je al hebt betaald.\n" +
    "Restituties zijn alleen mogelijk voor transacties die zijn uitgevoerd om Neurons te kopen en alleen in het geval van technische fouten met de Dienst die wordt geleverd op de Website en/of andere problemen die voortvloeien uit Onze eigen problemen die hebben geleid tot uw onvermogen om de Diensten te ontvangen waarvoor u hebt betaald.\n" +
    '"Technische problemen" worden gedefinieerd als aanzienlijke prestatieproblemen, systeemcrashes of het onvermogen om toegang te krijgen tot essentiële functies van ons platform als gevolg van fouten die toe te schrijven zijn aan onze systemen. Restituties worden niet verleend in gevallen van vermoede fraude of misbruik van onze Services. Het bedrijf behoudt zich het recht voor om eventuele claims grondig te onderzoeken en indien nodig passende juridische stappen te ondernemen om onze belangen en die van onze legitieme gebruikers te beschermen.\n' +
    "Terugbetalingsverzoeken moeten uiterlijk 24 uur na de transactiedatum worden ingediend via contact{'@'}aifactorysolution.com. Restitutieverzoeken worden binnen 14 werkdagen vanaf de datum van indiening verwerkt.\n" +
    "Gebruikers moeten gedetailleerde beschrijvingen, schermafbeeldingen, foutmeldingen of andere relevante documentatie verstrekken die hun beweringen over technische problemen onderbouwen. Verzoeken kunnen worden geweigerd als ze niet voldoen aan de gestelde criteria, waaronder onvoldoende bewijs van technische problemen of gevallen van fraude of misbruik.\n" +
    "Restituties worden niet uitgegeven in gevallen waarin de gebruiker gewoon ontevreden is over het product, de service of de kwaliteit van de gegenereerde inhoud zonder geldige technische redenen.",
  terms8:
    "Links op Ons Platform kunnen leiden naar websites of diensten van derden die geen eigendom zijn van de Onderneming. Websites of diensten van derden kunnen worden gekoppeld aan het bedrijf, maar het bedrijf is niet verantwoordelijk voor hun inhoud, privacybeleid of praktijken. U erkent en gaat ermee akkoord dat het bedrijf niet aansprakelijk kan worden gesteld voor schade of verlies veroorzaakt door of in verband met het gebruik van of het vertrouwen op dergelijke inhoud, goederen of diensten die worden aangeboden via websites of andere diensten. We raden u ten zeerste aan de gebruiksvoorwaarden en het privacybeleid te lezen van websites of diensten van derden die u bezoekt.",
  terms9:
    "Het principe van auteursrecht vereist dat we de intellectuele eigendomsrechten van anderen erkennen en respecteren. Het is ons beleid om elke claim dat op de Service geplaatste Content inbreuk maakt op intellectuele eigendomsrechten of auteursrechten van anderen aan te pakken. Indien u van mening bent dat de Service inbreuk op auteursrechten faciliteert, en u bent auteursrechthebbende of gemachtigd namens een auteursrechthebbende, stuur ons dan een e-mail op contact{'@'}aifactorysolution.com om uw melding in te dienen. Vermeld in uw melding een gedetailleerde uitleg van de vermeende inbreuk. Het toegeven van een onwaarheid dat enige Inhoud inbreuk maakt op uw auteursrecht kan ertoe leiden dat u aansprakelijk wordt gesteld voor schade, inclusief kosten en honoraria van advocaten.",
  terms10:
    "We hebben de bevoegdheid om je account op te schorten of te beëindigen zonder voorafgaande kennisgeving of aansprakelijkheid, zelfs als je de voorwaarden niet schendt. Dit geldt ongeacht de reden. Uw gebruik van de service wordt beëindigd wanneer u uw account verwijdert. Als je je account van het Platform wilt verwijderen, kun je gewoon stoppen met het gebruik van de Dienst. Deze Overeenkomst blijft volledig van kracht zolang u de Diensten gebruikt. Het Bedrijf heeft te allen tijde de bevoegdheid om uw toegang tot de Diensten te beëindigen of op te schorten. In het geval van een schending van een van de voorwaarden van deze Overeenkomst, kan het Bedrijf onmiddellijk alle Diensten en toegang tot de Website opschorten of beëindigen zonder enige aansprakelijkheid of waarschuwing.\n" +
    "Alle bepalingen van deze Overeenkomst die van kracht blijven na beëindiging zijn essentieel, met inbegrip van eigendomsbepalingen en disclaimers. Deze beperkingen zijn ook van toepassing op aansprakelijkheidsbeperkingen. Het Bedrijf, zijn moederbedrijven, dochterondernemingen, gelieerde bedrijven en werknemers zijn volledig verplicht om zich te verdedigen tegen alle vorderingen, aansprakelijkheden, schikkingen/veroordelingen, met inbegrip van advocaatkosten of schade als gevolg van uw gebruik van Diensten, schending van deze Overeenkomst, of de inbreuk door u of een andere partij van uw intellectuele eigendomsrechten of andere rechten.\n" +
    "Als een bepaling van deze Voorwaarden onafdwingbaar of ongeldig wordt geacht, worden de overige bepalingen gewijzigd en geïnterpreteerd om het beoogde doel te bereiken volgens de toepasselijke wetgeving zonder dat deze worden vervangen. Tenzij anders overeengekomen, zal de niet-toepassing van een recht of verplichting onder deze Voorwaarden geen invloed hebben op het vermogen van een van beide partijen om naleving van deze Overeenkomst af te dwingen. Bij geschillen is de oorspronkelijke Engelstalige versie van de Voorwaarden bepalend.\n" +
    "Deze Voorwaarden kunnen worden gewijzigd en kunnen door ons naar eigen goeddunken worden gewijzigd of vervangen. U erkent en gaat ermee akkoord gebonden te zijn aan de herziene voorwaarden als u toegang blijft houden tot of gebruik blijft maken van onze service nadat deze herzieningen van kracht zijn geworden. Als u niet akkoord gaat met de nieuwe voorwaarden, verzoeken wij u de website en de service niet langer te gebruiken.\n" +
    "Bedankt voor het kiezen van AI Factory! Als u vragen hebt over deze voorwaarden, kunt u contact met ons opnemen via contact{'@'}aifactorysolution.com.",
  updated: "Laatst bijgewerkt op 01 aug 2025",
};
