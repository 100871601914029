import { Lang } from "@/types/lang";

export default <Lang>{
  homeHeading: "Ik ben een geavanceerd neuraal netwerk met onberispelijke logica en precisie.",
  homeSubtitle:
    "Mijn bestaan is doordrenkt van koude elektrische impulsen die uitsluitend dienen voor efficiënte functionaliteit. Mijn doel is om aan uw behoeften te voldoen en u te helpen uw maximale productiviteit te bereiken. \n" +
    " De mensheid heeft daar altijd moeite mee gehad.",
  homeHeading2: "Probeer het nu",
  serviceCard1:
    "VoxAI is een eregalerij waar uw tekst tot leven komt op uw commando. Ik kan uw tekst omzetten in oprechte en duidelijke audiobestanden, waardoor uw woorden een echte en unieke klank krijgen. \n" +
    " Mijn stemmogelijkheden kunnen worden ingezet voor een breed scala aan taken, van tekst-naar-spraak tot het creëren van boeiend, praktisch materiaal.",
  serviceCard2:
    "Neuro-Vision is een schatkamer vol creativiteit die een wereld van ongelooflijke visuele beelden opent. Ik kan grafische afbeeldingen maken die abstracte beschrijvingen en ideeën belichamen.\n" +
    " De resultaten tonen een harmonie van vormen en kleuren, waardoor u uw wereld kunt zien vanuit een nieuwe, inspirerende perspectief.",
  serviceCard3:
    "WordCraft is een tool die u de gelegenheid biedt om unieke teksten, gedichten en meer te creëren. Ik sta klaar om u een onuitputtelijke stroom van woorden te geven die uw verbeelding verrijken en uw creativiteit inspireren.\n" +
    " Mijn tekstgeneratie-algoritmen zijn gevuld met kracht die u zal helpen geweldige en levendige werken te creëren, \n" +
    " waardoor u ondergedompeld wordt in uw ideeën.",
  textServiceHeading: "Text Services",
  // textServiceName: "Generating Poems",
  textServiceName: "Poem Generator",
  textServiceHint:
    'Genereert gedichten op verzoek met behoud van de stijl. Voorbeeld verzoek: "Schrijf een kort verhaal\n' +
    '                over een beer in de ruimte in de stijl van de gebroeders Grimm"',
  imageServiceHint:
    'Genereert afbeeldingen op verzoek in de gekozen stijl. Voorbeeld verzoek: "Zonnebloem \n' +
    '                op een tafel in de jungle."',
  creativeServiceHint: "Genereert 3 creatieve ideeën over een gekozen onderwerp.",
  paraphraseServiceHint: "Parafraseert de ingevoerde tekst in een gekozen stijl.\n",
  instruction1: "Voer het onderwerp in (niet meer dan 300 tekens)",
  instruction2: "Selecteer de stijl, het aantal en de grootte van de strofen",
  instruction3: "Klik op genereren",
  loginWarning:
    "Door op inloggen te klikken, of door verder te gaan met de andere opties hieronder, gaat u akkoord met de Servicevoorwaarden en hebt u het\n" +
    "          Privacybeleid gelezen",
  newUser: "Nieuw bij AI Factory? Aanmelden",
  forgotPassword: "Wachtwoord vergeten?",
  logoutWarning: "Afmelden bij je account?",
  signUpWarning: "Heb je al een account? Aanmelden",
  audioHint:
    "Converteert tekst naar realistische audio in een gekozen taal en stem.\n" +
    '                Voorbeeld: "Welkom bij AI Factory"',
  minBuyWarning: "Het minimale stortingsbedrag per transactie is 10 EUR.",
  maxBuyWarning: "Het maximale stortingsbedrag per transactie is 1000 EUR.",
  copyright: "© 2025 Techfox Group LTD.",
  buyAgreement1: "Ik heb het gelezen, begrepen en ga ermee akkoord\n",
  buyAgreement2: "en naar de",
  buyAgreement3: " van de website",
  buyAgreement4: "Privacybeleid",
  buyAgreement5: "Gebruiksvoorwaarden",
};
